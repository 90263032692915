//vars
$dark-color: #000000;
$white-color: #ffffff;
$regular-font : 'Poppins', sans-serif;
$container: 1950px;
$font-size: 15px;
$body-bg: #ffffff;
$theme-color: #ff00c0;
$body-font-color: #707070;
$primary-header-bg: #efefef;
$top-header-bg: #13121d;