@media(min-width:1400px) {
  .gray-boxes .gray-box .gray-box-wrapper h3 {
    font-size: 37px;
  }
  .item-grid .item {
    width: 20%;
  }
  .item-grid .item .preview .stats .stat-item {
    font-size: 15px;
  }
  .primary-header .col-logo .logo img {
    max-width: 330px;
  }
  .main-nav li a {
    padding-right: 10px;
    padding-left: 10px;
  }
  .main-nav li a,
  .primary-header .btn {
    font-size: 15px;
  }
  
}
@media(max-width:1200px) {
  .primary-header .col-logo .logo img {
    max-width: 200px;
  }
  .primary-header .col-logo .mobile-menu svg {
    width: 24px;
    height: 24px;
  }
  .primary-header .btn {
    padding: 10px;
  }
  .main-nav li a {
    font-size: 12px;
  }
}

@media(max-width:1024px) {
  .item-grid .item {
    width: 33.33%;
  }

  .sidebar:not(.collapse) + .sidebar-overlay {
    display: block;
  }

  .sidebar {
    position: fixed;
    left: 0;
    width: 245px;
    height: 100%;
    overflow: auto;
    z-index: 999;
    top: 0;
  }

  .footer-top .footer-box {
    margin-bottom: 20px;
    width: 25%;
    flex-grow: inherit;
  }

  .footer-top .footer-title h3 {
    font-size: 30px;
  }

  .primary-header .ph-col.col-menu {
    order: 2;
    width: 100%;
    padding-top: 15px;
    border-top: 1px solid #ddd;
    margin-top: 20px;
  }
  
  .gray-boxes {
    flex-wrap: wrap;
  }

  .gray-boxes .gray-box {
    width: 100%;
    margin-bottom: 10px;
  }

  .primary-header {
    flex-wrap: wrap;
  }
  .pagination>li>a {
    font-size: 12px;
    min-width: 22px;
    width: auto;
  }
  .pagination>li.next a svg {
    height: 10px;
    width: 10px;
  }
}

@media(max-width: 767px) {
  .footer-logo p {
    font-size: 14px;
  }
  .footer-top .footer-box {
    width: 33.33%;
  }
  .page-title h1 {
    font-size: 24px;
  }
  .pagination-wrap {
    padding: 15px 0;
  }
  .item-grid .item {
    width: 50%;
  }
  .item-grid .item .item-detail .btn {
    font-size: 12px;
    padding: 7px;
    height: auto;
  }
  .item-grid .item .item-detail h4 {
    font-size: 15px;
  }
  .item-grid .item .preview .stats .stat-item svg {
    width: 16px;
    height: 16px;
  }
  .gray-boxes .gray-box .gray-box-wrapper h3 {
    font-size: 20px;
  }
  .pagination>li>a {
    margin: 2px 0;
  }
  .pagination-wrap {
    padding: 15px;
  }
  .page-content {
    padding: 15px 15px 0;
  }
  .item-grid .item .item-detail {
    padding: 8px;
  }
  .item-grid .item .preview .stats {
    padding: 5px 8px;
  }
  .item-grid .item .preview .stats .stat-item {
    font-size: 10px;
  }
}

@media(max-width: 560px) {
  .primary-header .col-action {
    flex: 100%;
    text-align: center;
    justify-content: center;
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #ddd;
  }
  .gray-boxes .gray-box .gray-box-wrapper {
    padding: 20px;
  }
  .footer-top .footer-box {
    width: 50%;
  }
  .footer-top {
    padding: 0;
    padding-top: 20px;
  }
  .footer-top .footer-title h3 {
    font-size: 24px;
  }
}

@media(max-width: 360px) {
  .item-grid .item {
    width: 100%;
  }
}