*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* 4 */
  -ms-overflow-style: scrollbar;
  /* 5 */
  -webkit-tap-highlight-color: transparent;
  /* 6 */ }

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  background-color: #ffffff; }

main {
  display: block; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

ul.no-bullet {
  list-style: none;
  margin: 0;
  padding: 0; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  cursor: pointer; }

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%; }

.text-center {
  text-align: center; }

table {
  border-collapse: collapse;
  /* Prevent double borders */ }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  /* 3 */
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.text-uppercase {
  text-transform: uppercase; }

.bold {
  font-weight: 800; }

.text-white {
  color: #ffffff; }

.text-dark {
  color: #000000; }

.container {
  width: 1890px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%;
  margin: 0 auto; }

.btn {
  border: none;
  background-color: #ff00c0;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 800;
  text-align: center;
  border-radius: 3px;
  height: 39px;
  padding: 10px 14px;
  transition: 0.5s ease all;
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block; }
  .btn:hover, .btn:active, .btn:focus {
    background-color: #ffffff;
    color: #ff00c0;
    border-color: #ff00c0; }
  .btn.btn-login {
    margin-right: 10px;
    background-color: #d8d8d8;
    color: #000000; }
    .btn.btn-login:hover, .btn.btn-login:active, .btn.btn-login:focus {
      background-color: #ff00c0;
      color: #ffffff;
      border-color: #ff00c0; }

.primary-header {
  position: relative;
  background-color: #efefef;
  display: flex;
  padding: 23px 15px;
  align-items: center; }
  .primary-header .btn {
    font-size: 13px; }
  .primary-header .logo-wrapper {
    display: flex;
    align-items: center; }
  .primary-header .col-logo {
    display: flex;
    align-items: center; }
    .primary-header .col-logo .mobile-menu svg {
      fill: #000000; }
    .primary-header .col-logo .logo {
      margin-left: 15px; }
      .primary-header .col-logo .logo img {
        max-width: 250px; }
  .primary-header .ph-col {
    flex-grow: 1;
    flex-basis: 0;
    display: flex; }
    .primary-header .ph-col.col-menu {
      width: 100%;
      flex: auto;
      justify-content: center; }
  .primary-header .col-action {
    justify-content: flex-end; }
    .primary-header .col-action .btn-account {
      white-space: nowrap; }
  .primary-header .btn-search-mobile {
    background-color: transparent;
    border: 1px solid #696969;
    border-radius: 3px;
    padding: 7.5px 10px;
    margin-right: 10px;
    display: none; }
    .primary-header .btn-search-mobile svg {
      width: 20px;
      height: 20px;
      fill: #696969; }

.main-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .main-nav li.active a {
    color: #ff00c0; }
  .main-nav li:hover a, .main-nav li:active a {
    color: #ff00c0; }
  .main-nav li a {
    text-transform: uppercase;
    color: #000000;
    font-size: 13px;
    font-weight: 800;
    padding-left: 5px;
    padding-right: 5px;
    transition: 0.4s ease all; }

.sidebar {
  width: 245px;
  background-color: #000000;
  transition: 0.4s ease all; }
  .sidebar.collapse {
    margin-left: -245px; }
  .sidebar .widget {
    padding: 25px; }
    .sidebar .widget .widget-title {
      font-size: 28px;
      color: #ffffff;
      margin-bottom: 15px;
      text-transform: uppercase; }
    .sidebar .widget li {
      margin-top: 8px;
      margin-bottom: 8px; }
      .sidebar .widget li a {
        font-size: 15px;
        color: #707070;
        transition: 0.4s ease all; }
        .sidebar .widget li a:hover, .sidebar .widget li a:focus, .sidebar .widget li a:active {
          color: #ffffff; }

.main {
  display: flex; }
  .main .content {
    flex: 1; }

.page-content {
  padding: 30px;
  padding-bottom: 0; }

.page-head {
  font-size: 13px;
  color: #717171; }

.page-title {
  text-align: center; }
  .page-title h1 {
    margin-bottom: 10px;
    padding-bottom: 0;
    text-transform: uppercase;
    color: #000000;
    font-size: 33px; }
  .page-title p {
    font-size: 15px;
    color: #707070; }

.item-grid {
  display: flex;
  margin-left: -6px;
  margin-right: -6px;
  flex-wrap: wrap; }
  .item-grid .item {
    width: 25%;
    padding-left: 6px;
    padding-right: 6px;
    overflow: hidden;
    margin-bottom: 12px; }
    .item-grid .item .preview img {
      width: 100%; }
    .item-grid .item .preview .stats {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 5px 14px;
      background-color: rgba(0, 0, 0, 0.5); }
      .item-grid .item .preview .stats .stat-item {
        text-transform: uppercase;
        color: #ffffff;
        display: flex;
        align-items: center;
        font-weight: 800;
        font-size: 12px; }
        .item-grid .item .preview .stats .stat-item svg {
          width: 25px;
          height: 25px;
          fill: #ff00c0;
          margin-left: 5px; }
    .item-grid .item .item-wrapper .preview {
      position: relative; }
      .item-grid .item .item-wrapper .preview a {
        display: block; }
        .item-grid .item .item-wrapper .preview a:after {
          transition: opacity 0.3s;
          content: "";
          display: block;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(35, 35, 35, 0.4);
          position: absolute;
          z-index: 9;
          opacity: 0; }
    .item-grid .item .item-detail {
      background-color: #efefef;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .item-grid .item .item-detail h4 {
        text-transform: uppercase;
        font-size: 20px; }
      .item-grid .item .item-detail p {
        font-size: 13px;
        color: #9b9b9b;
        margin-bottom: 0; }
      .item-grid .item .item-detail .btn {
        background-color: #d8d8d8;
        text-transform: uppercase;
        color: #000000; }
    .item-grid .item:hover .item-wrapper .preview a:after, .item-grid .item:focus .item-wrapper .preview a:after, .item-grid .item:active .item-wrapper .preview a:after {
      opacity: 1;
      left: 0; }

.pagination-wrap {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px; }

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding-left: 0; }
  .pagination > li {
    margin-left: 2.5px;
    margin-right: 2.5px; }
    .pagination > li.next a svg {
      fill: #ffffff;
      transform: rotate(270deg); }
    .pagination > li.active a {
      background-color: #d8d8d8; }
    .pagination > li > a {
      padding: 5px;
      font-weight: 800;
      font-size: 22px;
      color: #ffffff;
      background-color: #ff00c0;
      width: 42px;
      display: block;
      text-align: center;
      border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px; }
    .pagination > li:hover > a {
      background-color: #d8d8d8;
      color: #ff00c0; }

.gray-boxes {
  margin-left: -7px;
  margin-right: -7px;
  display: flex; }
  .gray-boxes .gray-box {
    width: 33.3333%;
    padding-left: 7px;
    padding-right: 7px; }
    .gray-boxes .gray-box .gray-box-wrapper {
      background-color: #efefef;
      padding: 30px;
      border-bottom: 1px solid #d8d8d8; }
      .gray-boxes .gray-box .gray-box-wrapper h3 {
        text-transform: uppercase;
        font-size: 26px;
        font-weight: 800; }
      .gray-boxes .gray-box .gray-box-wrapper p {
        line-height: 1.8;
        font-size: 15px;
        margin-bottom: 0;
        color: #707070; }

.footer-top {
  background-color: #efefef;
  margin-top: 40px;
  padding: 25px;
  border-bottom: 1px solid #d8d8d8; }
  .footer-top .footer-top-content {
    display: flex;
    flex-wrap: wrap; }
  .footer-top .footer-title {
    text-align: center;
    margin-bottom: 40px; }
    .footer-top .footer-title h3 {
      text-align: center;
      font-size: 38px;
      text-transform: uppercase;
      font-weight: 800; }
    .footer-top .footer-title p {
      font-size: 15px; }
  .footer-top .footer-box {
    flex-grow: 1;
    padding-right: 15px; }
    .footer-top .footer-box ul {
      list-style-type: none;
      padding-left: 0; }
      .footer-top .footer-box ul li {
        margin-bottom: 15px; }
        .footer-top .footer-box ul li a {
          color: #707070;
          transition: 0.4s ease all; }
        .footer-top .footer-box ul li:hover a {
          color: #ff00c0;
          font-weight: 800; }

footer {
  display: flex;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px; }

.footer-logo {
  text-align: center; }
  .footer-logo img {
    margin-bottom: 20px;
    width: 395px; }
  .footer-logo p {
    font-size: 15px;
    color: #9f9e9e; }
    .footer-logo p a {
      color: #9f9e9e;
      transition: 0.4 ease all; }
      .footer-logo p a:hover, .footer-logo p a:focus {
        color: #ff00c0; }

.sidebar-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  display: none; }

.close-sidebar {
  position: fixed;
  top: 0;
  left: 245px;
  z-index: 999; }
  .close-sidebar svg {
    fill: #ffffff; }

@media (min-width: 1400px) {
  .gray-boxes .gray-box .gray-box-wrapper h3 {
    font-size: 37px; }
  .item-grid .item {
    width: 20%; }
  .item-grid .item .preview .stats .stat-item {
    font-size: 15px; }
  .primary-header .col-logo .logo img {
    max-width: 330px; }
  .main-nav li a {
    padding-right: 10px;
    padding-left: 10px; }
  .main-nav li a,
  .primary-header .btn {
    font-size: 15px; } }

@media (max-width: 1200px) {
  .primary-header .col-logo .logo img {
    max-width: 200px; }
  .primary-header .col-logo .mobile-menu svg {
    width: 24px;
    height: 24px; }
  .primary-header .btn {
    padding: 10px; }
  .main-nav li a {
    font-size: 12px; } }

@media (max-width: 1024px) {
  .item-grid .item {
    width: 33.33%; }
  .sidebar:not(.collapse) + .sidebar-overlay {
    display: block; }
  .sidebar {
    position: fixed;
    left: 0;
    width: 245px;
    height: 100%;
    overflow: auto;
    z-index: 999;
    top: 0; }
  .footer-top .footer-box {
    margin-bottom: 20px;
    width: 25%;
    flex-grow: inherit; }
  .footer-top .footer-title h3 {
    font-size: 30px; }
  .primary-header .ph-col.col-menu {
    order: 2;
    width: 100%;
    padding-top: 15px;
    border-top: 1px solid #ddd;
    margin-top: 20px; }
  .gray-boxes {
    flex-wrap: wrap; }
  .gray-boxes .gray-box {
    width: 100%;
    margin-bottom: 10px; }
  .primary-header {
    flex-wrap: wrap; }
  .pagination > li > a {
    font-size: 12px;
    min-width: 22px;
    width: auto; }
  .pagination > li.next a svg {
    height: 10px;
    width: 10px; } }

@media (max-width: 767px) {
  .footer-logo p {
    font-size: 14px; }
  .footer-top .footer-box {
    width: 33.33%; }
  .page-title h1 {
    font-size: 24px; }
  .pagination-wrap {
    padding: 15px 0; }
  .item-grid .item {
    width: 50%; }
  .item-grid .item .item-detail .btn {
    font-size: 12px;
    padding: 7px;
    height: auto; }
  .item-grid .item .item-detail h4 {
    font-size: 15px; }
  .item-grid .item .preview .stats .stat-item svg {
    width: 16px;
    height: 16px; }
  .gray-boxes .gray-box .gray-box-wrapper h3 {
    font-size: 20px; }
  .pagination > li > a {
    margin: 2px 0; }
  .pagination-wrap {
    padding: 15px; }
  .page-content {
    padding: 15px 15px 0; }
  .item-grid .item .item-detail {
    padding: 8px; }
  .item-grid .item .preview .stats {
    padding: 5px 8px; }
  .item-grid .item .preview .stats .stat-item {
    font-size: 10px; } }

@media (max-width: 560px) {
  .primary-header .col-action {
    flex: 100%;
    text-align: center;
    justify-content: center;
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #ddd; }
  .gray-boxes .gray-box .gray-box-wrapper {
    padding: 20px; }
  .footer-top .footer-box {
    width: 50%; }
  .footer-top {
    padding: 0;
    padding-top: 20px; }
  .footer-top .footer-title h3 {
    font-size: 24px; } }

@media (max-width: 360px) {
  .item-grid .item {
    width: 100%; } }
