*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%; /* 4 */
  -ms-overflow-style: scrollbar; /* 5 */
  -webkit-tap-highlight-color: transparent; /* 6 */
}

body {
	margin: 0;
	font-family: $regular-font;
	font-size: $font-size;
	background-color: $body-bg;
}

main {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul.no-bullet {
	list-style: none;
	margin: 0;
	padding: 0;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  background-color: transparent;
	-webkit-text-decoration-skip: objects;
	cursor: pointer;
}

img {
  vertical-align: middle;
	border-style: none;
	max-width: 100%;
}

.text-center {
	text-align: center;
}

table {
  border-collapse: collapse; /* Prevent double borders */
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}


sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}


button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0; /* 3 */
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

.text-uppercase {
	text-transform: uppercase;
}

.bold {
	font-weight: 800;
}

.text-white {
	color: $white-color;
}

.text-dark {
	color: $dark-color;
}

.container {
	width: 1890px;
	padding-left: 15px;
	padding-right: 15px;
	max-width: 100%;
	margin: 0 auto;
}

.btn {
	border: none;
	background-color: $theme-color;
	color: #ffffff;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 800;
	text-align: center;
	border-radius: 3px;
	height: 39px;
	padding: 10px 14px;
	transition: 0.5s ease all;
	border: 1px solid transparent;
	cursor: pointer;
	display: inline-block;

	&:hover,
	&:active,
	&:focus {
		background-color: #ffffff;
		color: $theme-color;
		border-color: $theme-color;

	}

	&.btn-login {
		margin-right: 10px;
		background-color: #d8d8d8;
		color: #000000;

		&:hover,
		&:active,
		&:focus {
			background-color: $theme-color;
			color: #ffffff;
			border-color: $theme-color;

		}	
	}
}

//header
.primary-header {
	position: relative;
	background-color: $primary-header-bg;
	display: flex;
	padding: 23px 15px;
	align-items: center;

	.btn {
		font-size: 13px;
	}

	.logo-wrapper {
		display: flex;
		align-items: center;
	}

	.col-logo {
		display: flex;
		align-items: center;

		.mobile-menu {
			svg {
				fill: #000000;
			}
		}

		.logo {
			margin-left: 15px;

			img {
				max-width: 250px;
			}
		}

	}

	.ph-col {
		flex-grow: 1;
		flex-basis: 0;
		display: flex;

		&.col-menu {
			width: 100%;
			flex: auto;
			justify-content: center;
		}
	}

	.col-action {
		justify-content: flex-end;

		.btn-account {
			white-space: nowrap;
		} 
	}

	.btn-search-mobile {
		background-color: transparent;
		border: 1px solid #696969;
		border-radius: 3px;
		padding: 7.5px 10px;
		margin-right: 10px;
		display: none;

		svg {
			width: 20px;
			height: 20px;
			fill: #696969;
		}
	}

	
}

.main-nav {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	li {
		&.active {
			a {
				color: $theme-color;
			}
		}

		&:hover,
		&:active {
			a {
				color: $theme-color;
			}
		}
		

		a {
			text-transform: uppercase;
			color: #000000;
			font-size: 13px;
			font-weight: 800;
			padding-left: 5px;
			padding-right: 5px;
			transition: 0.4s ease all;
		}
	}
}

.sidebar {
	width: 245px;
	background-color: #000000;
	transition: 0.4s ease all;
	
	&.collapse {
		margin-left: -245px;
	}

	.widget {
		padding: 25px;

		.widget-title {
			font-size: 28px;
			color: #ffffff;
			margin-bottom: 15px;
			text-transform: uppercase;
		}

		
		li {
			margin-top: 8px;
			margin-bottom: 8px;

			a {
				font-size: 15px;
				color: #707070;
				transition: 0.4s ease all;

				&:hover,
				&:focus,
				&:active {
					color: #ffffff;
				}
			}
		}
	}
}

.main {
	display: flex;

	.content {
		flex: 1;
	}
}


.page-content {
	padding: 30px;
	padding-bottom: 0;
}

.page-head {
	font-size: 13px;
	color: #717171;
}

.page-title {
	text-align: center;

	h1 {
		margin-bottom: 10px;
		padding-bottom: 0;
		text-transform: uppercase;
		color: #000000;
		font-size: 33px;
	}
	p {
		font-size: 15px;
		color: #707070;
	}
}

.item-grid {
	display: flex;
	margin-left: -6px;
	margin-right: -6px;
	flex-wrap: wrap;

	.item {
		width: 25%;
		padding-left: 6px;
		padding-right: 6px;
		overflow: hidden;
		margin-bottom: 12px;

		.preview {
			img {
				width: 100%;
			}

			.stats {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				display: flex;
				justify-content: space-between;
				padding: 5px 14px;
				background-color: rgba(0, 0, 0, 0.5);

				.stat-item {
					text-transform: uppercase;
					color: #ffffff;
					display: flex;
					align-items: center;
					font-weight: 800;
					font-size: 12px;

					svg {
						width: 25px;
						height: 25px;
						fill: $theme-color;
						margin-left: 5px;
					}
				}
			}
		}
		

		.item-wrapper {
			.preview {
				position: relative;

				a {
					display: block;

					&:after {
						transition: opacity 0.3s;
						content: "";
						display: block;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background-color: rgba(35, 35, 35, 0.4);
						position: absolute;
						z-index: 9;
						opacity: 0;
					}
				}
			}
		}

		.item-detail {
			background-color: #efefef;
			padding: 15px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			h4 {
				text-transform: uppercase;
				font-size: 20px;
			}

			p {
				font-size: 13px;
				color: #9b9b9b;
				margin-bottom: 0;
			}

			.btn {
				background-color: #d8d8d8;
				text-transform: uppercase;
				color: #000000;
			}

			
		}

		&:hover,
		&:focus,
		&:active {
			.item-wrapper {
				.preview {
					a {
						&:after {
							opacity: 1;
							left: 0;
						}
					}
				}
			}
		}
	}
}

.pagination-wrap {
	display: flex;
	justify-content: center;
	padding-top: 30px;
	padding-bottom: 30px;	
}

.pagination {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	list-style: none;
	padding-left: 0;

	> li {
		margin-left: 2.5px;
		margin-right: 2.5px;

		&.next {
			a {
				svg {
					fill: #ffffff;
					transform: rotate(270deg);
				}
			}
		}

		&.active {
			a {
				background-color: #d8d8d8;
			}
		}

		>a {
			padding: 5px;
			font-weight: 800;
			font-size: 22px;
			color: #ffffff;
			background-color: $theme-color;
			width: 42px;
			display: block;
			text-align: center;
			border-radius: 2px;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			-ms-border-radius: 2px;
			-o-border-radius: 2px;
		}

		&:hover {
			> a {
				background-color: #d8d8d8;
				color: $theme-color;
			}
		}
	}
}

.gray-boxes {
	margin-left: -7px;
	margin-right: -7px;
	display: flex;

	.gray-box {
		width: 33.3333%;
		padding-left: 7px;
		padding-right: 7px;
		
		.gray-box-wrapper {
			background-color: #efefef;
			padding: 30px;
			border-bottom: 1px solid #d8d8d8;

			h3 {
				text-transform: uppercase;
				font-size: 26px;
				font-weight: 800;				
			}

			p {
				line-height: 1.8;
				font-size: 15px;
				margin-bottom: 0;
				color: #707070;
			}
		}
	}
}

.footer-top {
	background-color: #efefef;
	margin-top: 40px;
	padding: 25px;
	border-bottom: 1px solid #d8d8d8;

	.footer-top-content {
		display: flex;
		flex-wrap: wrap;
	}

	.footer-title {
		text-align: center;
		margin-bottom: 40px;
		
		h3 {
			text-align: center;
			font-size: 38px;
			text-transform: uppercase;
			font-weight: 800;
		}
		p {
			font-size: 15px;
		}
	}

	.footer-box {
		flex-grow: 1;
		padding-right: 15px;

		ul {
			list-style-type: none;
			padding-left: 0;

			li {
				margin-bottom: 15px;
				a {
					color: #707070;
					transition: 0.4s ease all;
				}

				&:hover {
					a {
						color: $theme-color;
						font-weight: 800;
					}
				}
			}
		}

	}
}

footer {
	display: flex;
	align-items: center;
	padding-top: 25px;
	padding-bottom: 25px;
		
}

.footer-logo {
	text-align: center;
	img {
		margin-bottom: 20px;
		width: 395px;	
	}
	p {
		font-size: 15px;
		color: #9f9e9e;

		a {
			color: #9f9e9e;
			transition: 0.4 ease all;

			&:hover,
			&:focus {
				color: $theme-color;
			}
		}
	}
}

.sidebar-overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9;
	display: none;
}



.close-sidebar {
		position: fixed;
    top: 0;
		left: 245px;
		z-index: 999;
		svg {
			fill: #ffffff;
		}
}